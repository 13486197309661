import React from 'react';
import PropTypes from 'prop-types';
import { Layout } from 'antd';
import styled from 'styled-components';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { Splash } from './splash';
import { Header } from './header';
import {
  AuthenticationProvider,
  ClientProvider,
  BotProvider,
  UserStatusProvider
} from '../uni-comms-api/hooks/authentication';
import StudioEventsProvider from './context/StudioEventsProvider';
import { ClawsonProvider } from './clawson';
import { MessengerProvider } from './messenger';
import UpdateTCSBanner from './UpdateTCSBanner';
import AICreatedBanner from './AICreatedBanner';
import NotificationsBanner from './NotificationsBanner';
import { useWindowSize } from '../utils';
import { SCREEN_WIDTH } from '../constants';
import MobileView from './MobileView';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

export default function Shell({ settings, children }) {
  const { width: screenWidth } = useWindowSize();

  const isMobile = screenWidth < SCREEN_WIDTH.SMALL;

  return (
    <QueryClientProvider client={queryClient}>
      <AuthenticationProvider
        settings={settings.auth}
        loadingFallback={<Splash />}
      >
        <ClientProvider loadingFallback={<Splash />}>
          <BotProvider loadingFallback={<Splash />}>
            <UserStatusProvider>
              <ClawsonProvider messenger={settings.clawson}>
                <MessengerProvider messenger={settings.messenger}>
                  <MainLayout>
                    {isMobile ? (
                      <MobileView />
                    ) : (
                      <>
                        <StudioEventsProvider>
                          <Header />
                          <NotificationsBanner />
                          <AICreatedBanner />
                          <UpdateTCSBanner />
                          <MainLayoutContent>{children}</MainLayoutContent>
                        </StudioEventsProvider>
                      </>
                    )}
                  </MainLayout>
                </MessengerProvider>
              </ClawsonProvider>
            </UserStatusProvider>
          </BotProvider>
        </ClientProvider>
      </AuthenticationProvider>
    </QueryClientProvider>
  );
}

Shell.propTypes = {
  settings: PropTypes.shape({
    auth: PropTypes.shape({
      authURL: PropTypes.string.isRequired,
      loginURL: PropTypes.string.isRequired,
      clientID: PropTypes.string.isRequired
    }).isRequired,
    messenger: PropTypes.shape({
      url: PropTypes.string.isRequired
    }).isRequired,
    clawson: PropTypes.shape({
      url: PropTypes.string.isRequired,
      client: PropTypes.string.isRequired,
      pipeline: PropTypes.string.isRequired
    }).isRequired,
    billing: PropTypes.shape({
      url: PropTypes.string.isRequired
    }).isRequired
  }),
  children: PropTypes.node.isRequired
};

const MainLayout = styled(Layout)`
  height: 100vh;
  overflow: hidden;
`;

const MainLayoutContent = styled(Layout.Content)`
  height: 100%;
  background-color: #e5f3f2;
`;
