import { decodeSpecialChars } from './decodeSpecialChars';

const applicationName = 'AI Studio';

export class AgentNotification {
  constructor() {
    this.icon = '/static/media/lobster-white.7bd385da.png';
    this.permisionGranted = Notification.permission === 'granted';
    this.permisionDenied = Notification.permission === 'denied';
    this.permisionDefault = Notification.permission === 'default';
  }

  joinedChatOption = {
    body: 'Anonymous User: Joined Chat',
    icon: this.icon,
    requireInteraction: true
  };

  messageChatOption = value => {
    return {
      body: `Anonymous User: ${decodeSpecialChars(value)}`,
      icon: this.icon,
      requireInteraction: true
    };
  };

  requestPermission = () => {
    return Notification.requestPermission();
  };

  sendJoinedChatNotification = chat_id => {
    if (!('Notification' in window)) {
      return;
    } else if (this.permisionDefault) {
      Promise.resolve(Notification.requestPermission()).then(status => {
        if (status === 'granted') {
          const notif = new Notification(
            applicationName,
            this.joinedChatOption
          );

          notif.onclick = function () {
            window.open('/live-chat/' + chat_id, '_self').focus();
            notif.close();
          };

          return notif;
        } else if (status === 'denied') {
          return;
        }
      });
    } else if (this.permisionGranted) {
      const notif = new Notification(applicationName, this.joinedChatOption);

      notif.onclick = function () {
        window.open('/live-chat/' + chat_id, '_self').focus();
        notif.close();
      };

      return notif;
    } else if (this.permisionDenied) {
      return;
    }
  };

  sendNewMessageNotification = value => {
    if (!('Notification' in window)) {
      return;
    } else if (this.permisionDefault) {
      Promise.resolve(Notification.requestPermission()).then(status => {
        if (status === 'granted') {
          return new Notification(
            applicationName,
            this.messageChatOption(value)
          );
        } else if (status === 'denied') {
          return;
        }
      });
    } else if (this.permisionGranted) {
      return new Notification(applicationName, this.messageChatOption(value));
    } else if (this.permisionDenied) {
      return;
    }
  };
}
